import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { StorageModule } from '@ngx-pwa/local-storage';
import { MaterialModules } from './helpers/material.modules';
import { LayoutModules } from './layout/layout.modules';
import { AccountService } from './configuration-management/services/account.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor } from './helpers/jwt.interceptor';
import { AppRoutingModule } from './app.routing';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SyncService } from './shared/offline/sync-service';
import { SidebarComponent } from './layout/sidebar/sidebar.component';
import { HeaderComponent } from './layout/header/header.component';
import { SearchComponent } from './layout/header/search-component/search.component';
import { SharedModules } from './shared/shared.module';
import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';
import { AgmCoreModule } from '@agm/core';
import { MonacoEditorModule } from 'ngx-monaco-editor';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatNativeDatetimeModule } from '@mat-datetimepicker/core';
import { SyncDialogComponent } from './layout/sidebar/sync-dialog/sync.dialog';
import { PwaService } from './helpers/prompt.service';
import { PromptComponent } from './prompt-install/prompt-install';
import { UserIdleModule } from 'angular-user-idle';
import {ConfigLoaderService} from './config-loader.service';
import {FeatureFlagService} from './feature-flag.service';
import {PreloadFactory} from './preload-factory';

const initializer = (pwaService: PwaService) => () => pwaService.initPwaPrompt();

@NgModule({
    declarations: [
        AppComponent,
        SidebarComponent,
        HeaderComponent,
        SearchComponent,
        SyncDialogComponent,
        PromptComponent
    ],
    imports: [
        MatMomentDateModule,
        MatNativeDatetimeModule,
        BrowserModule,
        SharedModules,
        MaterialModules,
        LayoutModules,
        BrowserAnimationsModule,
        AppRoutingModule,
        UserIdleModule.forRoot({idle: 1800, timeout: 1, ping: 5}),
        MonacoEditorModule.forRoot(),
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyChakQDFaXwX19RmS0mal4dZBvDeq3Wp84',
            libraries: ['places']
        }),
        MatGoogleMapsAutocompleteModule.forRoot(),
        StorageModule.forRoot({
            IDBNoWrap: true,
        }),
        ServiceWorkerModule.register('ngsw-worker.js', {enabled: true})
    ],
    providers: [
        AccountService,
        SyncService,
        ConfigLoaderService,
        FeatureFlagService,
        {provide: APP_INITIALIZER, deps: [ConfigLoaderService, FeatureFlagService], multi: true, useFactory: PreloadFactory},
        {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
        {provide: APP_INITIALIZER, useFactory: initializer, deps: [PwaService], multi: true},
    ],
    entryComponents: [SyncDialogComponent, PromptComponent],
    bootstrap: [AppComponent]
})
export class AppModule { }
