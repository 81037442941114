import { NgModule } from '@angular/core';
import { ChipsComponent } from '../shared/chips/chips.component';
import { MaterialModules } from '../helpers/material.modules';
import { CommonModule } from '@angular/common';
import { NotesComponent } from './notes/notes.component';
import { TagsComponent } from './tags/tags.component';
import { SearchClientsComponent } from './search/search-clients/search-clients.component';
import { SearchPatientsComponent } from './search/search-patients/search-patients.component';
import { SearchLineItemsComponent } from './search/search-line-items/search-line-items.component';
import { SearchItemsComponent } from './search/search-items/search-items.component';
import { WhiteboardPromptDialogComponent } from './whiteboard-prompt/whiteboard-prompt.dialog';
import { NoGroupsSearchLineItemsComponent } from './search/search-line-items-no-groups/search-line-items-no-groups.component';
import { SafePipe } from './pipes/dom-sanitizer.pipe';
import { PhonePipe } from './pipes/phone-formatter.pipe';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation.dialog';
import { PatientReproductiveLogDialogComponent } from './patient-reproductive-log-dialog/patient-reproductive-log.dialog';
import { PatientVitalGroupDialogComponent } from './patient-vital-group-dialog/patient-vital-group.dialog';
import { MatDatetimepickerModule } from '@mat-datetimepicker/core';
import { PaymentDialogComponent } from '../line-item-entry/invoice/payment-dialog/payment.dialog';
import { SatDatepickerModule, SatNativeDateModule } from 'saturn-datepicker';
import { ControlledSubstanceLogDialogComponent } from './controlled-substance-log-dialog/controlled-substance-log.dialog';
import { StringFilterByPipe } from './pipes/stringFilterBy.pipe';
import { ClientViewDialog } from './search/client-view-dialog/client-view.dialog';
import { PatientViewDialog } from './search/patient-view-dialog/patient-view.dialog';
import { SendToOtherDialogComponent } from './send-to-other-dialog/send-to-other.dialog';
import { SendPrintComponent } from './send-print-button/send-print.component';
import { ItemLogDialogComponent } from './item-log-dialog/item-log.dialog';
import { ShowTagsComponent } from './tags/show-tags/show-tags.component';
import { BranchFilter } from './filter/branch-filter';
import { RequestsResultsTable } from './request-results-table/requests-results-table';
import { RouterModule } from '@angular/router';
import { SpeciesPreferenceFilterComponent } from './species-preference-filter/species-preference-filter/species-preference-filter.component';
import { SelectCheckAllComponent } from './select-check-all/select-check-all.component';
import { OverviewReminderPreferenceFilterComponent } from './species-preference-filter/overview-reminder-preference-filter/overview-reminder-preference-filter.component';
import { InvoiceStatusComponent } from '../shared/invoice-status/invoice-status.component';
import { MultiselectStaffComponent } from './multiselect-staff/multiselect-staff.component';
import {SelectStaffComponent} from "./select-staff/select-staff.component";


@NgModule({
    imports: [
        MaterialModules,
        CommonModule,
        MatDatetimepickerModule,
        SatDatepickerModule,
        SatNativeDateModule,
        RouterModule,
    ],
  exports: [
    ChipsComponent,
    NotesComponent,
    TagsComponent,
    SearchClientsComponent,
    SearchPatientsComponent,
    SearchLineItemsComponent,
    SearchItemsComponent,
    WhiteboardPromptDialogComponent,
    NoGroupsSearchLineItemsComponent,
    SafePipe,
    StringFilterByPipe,
    PhonePipe,
    ConfirmationDialogComponent,
    PatientReproductiveLogDialogComponent,
    PatientVitalGroupDialogComponent,
    PaymentDialogComponent,
    ControlledSubstanceLogDialogComponent,
    SendToOtherDialogComponent,
    SendPrintComponent,
    PatientViewDialog,
    ShowTagsComponent,
    BranchFilter,
    RequestsResultsTable,
    SpeciesPreferenceFilterComponent,
    OverviewReminderPreferenceFilterComponent,
    InvoiceStatusComponent,
    MultiselectStaffComponent,
    SelectStaffComponent
  ],
  declarations: [
    ChipsComponent,
    NotesComponent,
    TagsComponent,
    SearchClientsComponent,
    SearchPatientsComponent,
    SearchLineItemsComponent,
    SearchItemsComponent,
    WhiteboardPromptDialogComponent,
    ConfirmationDialogComponent,
    NoGroupsSearchLineItemsComponent,
    SafePipe,
    PhonePipe,
    StringFilterByPipe,
    PatientReproductiveLogDialogComponent,
    PatientVitalGroupDialogComponent,
    PaymentDialogComponent,
    ControlledSubstanceLogDialogComponent,
    ClientViewDialog,
    PatientViewDialog,
    SendToOtherDialogComponent,
    SendPrintComponent,
    ItemLogDialogComponent,
    ShowTagsComponent,
    BranchFilter,
    RequestsResultsTable,
    SpeciesPreferenceFilterComponent,
    SelectCheckAllComponent,
    OverviewReminderPreferenceFilterComponent,
    InvoiceStatusComponent,
    MultiselectStaffComponent,
    SelectStaffComponent
  ],
  entryComponents: [
    WhiteboardPromptDialogComponent,
    ConfirmationDialogComponent,
    PatientReproductiveLogDialogComponent,
    PatientVitalGroupDialogComponent,
    ControlledSubstanceLogDialogComponent,
    ClientViewDialog,
    SendToOtherDialogComponent,
    PatientViewDialog,
    ItemLogDialogComponent,
    ShowTagsComponent
  ]
})
export class SharedModules { }


