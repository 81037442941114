import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {CommunicationRequest, Request} from '../../../models/request.interface';
import {RequestService} from '../../../services/request.service';
import {FormControl, FormGroup} from '@angular/forms';
import {FileUploadModel} from 'src/app/patient-management/patient-record/patient-picture-dialog/patient-picture.dialog';
import FileSaver from 'file-saver';
import {AccountService} from '../../../../configuration-management/services/account.service';
import {ConfirmationDialogComponent} from 'src/app/shared/confirmation-dialog/confirmation.dialog';
import {PrintSend} from 'src/app/shared/send-print-button/send-print.model';
import {EmailViewModel} from 'src/app/configuration-management/models/email.interface';
import {CommunicationRequestViewModel, CommunicationViewModel} from '../../../../client-management/models/communication.interface';

@Component({
  selector: 'app-service-results',
  templateUrl: './communication-results.dialog.html',
  styleUrls: ['./communication-results.dialog.scss']
})
export class CommunicationResultsDialogComponent implements OnInit {
  request: CommunicationRequestViewModel;
  communications: CommunicationViewModel[];
  initialCommunication: CommunicationViewModel; // Communication created by staff alongside the CommunicationRequest
  medicalRecord: EmailViewModel;
  communication: EmailViewModel;
  reloadMedicalRecord = false;
  formIsValid: boolean;
  selectedDate: Date;
  emailSent: boolean;
  emailSentDate: Date;
  public files: Array<FileUploadModel> = [];
  resultsForm = new FormGroup({
    patientId: new FormControl(''),
    clientId: new FormControl(''),
    requestId: new FormControl(''),
    assignedUserIds: new FormControl([]),
    receiverAddress: new FormControl(''),
    receiverPhone: new FormControl(''),
    subject: new FormControl(''),
    textBody: new FormControl(''),
    responseStatus: new FormControl(''),
    urgency: new FormControl(''),
    preferredContactMethod: new FormControl(''),
    emailNotes: new FormControl(''),
    requestNotes: new FormControl(''),
    messageNumber: new FormControl(''),
    requestStatus: new FormControl('')
  });

  constructor(
    public dialogRef: MatDialogRef<CommunicationResultsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Request,
    public snackBar: MatSnackBar,
    public requestService: RequestService,
    public accountService: AccountService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.emailSent = false;
    this.getRequest();
  }

  getRequest() {
    this.requestService.getRequest<CommunicationRequestViewModel>(this.data.id).subscribe(res => {
      this.request = res;
      this.communications = this.request.communications;
      this.initialCommunication = this.communications[0];
      if (this.communications[1]) { // Email has already been sent to client in response to CommunicationRequest
        this.emailSent = this.communications[1].emailSent;
        this.emailSentDate = this.communications[1].emailSentDate;
        this.resultsForm.controls.emailNotes.disable();
      }
      this.selectedDate = this.request.orderDate;
      this.resultsForm.controls.patientId.setValue(this.initialCommunication.patientId);
      this.resultsForm.controls.clientId.setValue(this.initialCommunication.clientId);
      this.resultsForm.controls.requestId.setValue(this.request.id);
      this.resultsForm.controls.assignedUserIds.setValue(this.request.assignees.map(a => a.id));
      this.resultsForm.controls.receiverAddress.setValue(this.initialCommunication.receiverAddress);
      this.resultsForm.controls.receiverPhone.setValue(this.initialCommunication.receiverPhone);
      this.resultsForm.controls.subject.setValue(this.initialCommunication.subject);
      this.resultsForm.controls.textBody.setValue(this.initialCommunication.textBody);
      this.resultsForm.controls.responseStatus.setValue(this.request.responseStatus);
      this.resultsForm.controls.urgency.setValue(this.request.urgency);
      this.resultsForm.controls.preferredContactMethod.setValue(this.request.preferredContactMethod);
      this.resultsForm.controls.emailNotes.setValue(this.request.emailNotes);
      this.resultsForm.controls.requestNotes.setValue(this.request.requestNotes);
      this.resultsForm.controls.messageNumber.setValue(this.request.messageNumber);
      this.resultsForm.controls.requestStatus.setValue(this.request.requestStatus);
    });
  }

  updateRequestNotes() {
    const requestNotes = this.resultsForm.controls.requestNotes.value;
    this.requestService.updateRequestNotes(this.data.id, requestNotes).subscribe(res => {
      this.request.requestNotes = requestNotes;
      this.modelChanged();
      this.snackBar.open('Request notes have been updated', 'Success', {
        duration: 2000,
      });
      this.getRequest();
    },
      error => {
        this.snackBar.open('Request notes could not be updated', 'Error', {
          duration: 2000,
        });
      });
  }

  updateEmailNotes() {
    const emailNotes = this.resultsForm.controls.emailNotes.value;
    this.requestService.updateEmailNotes(this.data.id, emailNotes).subscribe(res => {
      this.request.emailNotes = emailNotes;
      this.modelChanged();
      this.snackBar.open('Email notes have been updated', 'Success', {
        duration: 2000,
      });
      this.getRequest();
    },
      error => {
        this.snackBar.open('Email notes could not be updated', 'Error', {
          duration: 2000,
        });
      });
  }

  updateRequestStatus(requestStatus: string) {}

  setMedicalRecord(medicalRecord: EmailViewModel) {
    this.medicalRecord = medicalRecord;
    this.reloadMedicalRecord = true;

    setTimeout(() => {
      this.reloadMedicalRecord = false;
    });
  }

  updateFormInputs() {
    this.resultsForm = new FormGroup({
      requestNotes: new FormControl(this.request.requestNotes),
      emailNotes: new FormControl(this.request.emailNotes)
    });
  }

  checkFormIsValid() {
    this.formIsValid = this.resultsForm.valid;
  }

  sendEmail() {
    this.modelChanged();
    const updatedRequest = this.resultsForm.getRawValue() as CommunicationRequest;
    this.requestService.respondToCommunicationRequest(updatedRequest).subscribe(
      res => {
        this.snackBar.open('Email sent', 'SUCCESS', {duration: 3000});
        this.request = null;
        this.initialCommunication = null;
        this.getRequest(); // refresh component data
      },
      error => {
        this.snackBar.open('Error sending email', 'ERROR', {duration: 3000});
      }
    );
  }

  cancel() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: {
        header: 'Cancel Communication Request',
        body: `You are about to cancel a communication request for ${this.request.clientName} regarding their patient ${this.request.patientName}.`,
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.requestService.cancellRequest(this.request.id).subscribe(res => {

          this.snackBar.open('Request has been cancelled', 'Success', {
            duration: 2000,
          });
          this.dialogRef.close('reload');
        },
          error => {
            this.snackBar.open('Request could not be cancelled', 'Error', {
              duration: 2000,
            });
          });

      } else {
        // cancel
        this.snackBar.open('Operation cancelled.', '', {
          duration: 2000,
        });
      }
    });
  }

  checkValid(index): boolean {
    const control = this.resultsForm.get(['labResults', index]);

    return (control.valid) || (!control.valid && control.value.noAnswer === true);
  }

  modelChanged() {
    this.checkFormIsValid();
  }

  setMedicalNote(model: EmailViewModel) {
    this.communication = model;
  }

  save() {
    this.modelChanged();
    const updatedRequest = this.resultsForm.getRawValue() as CommunicationRequest;

    this.requestService.updateCommunicationRequest(updatedRequest).subscribe(
      res => {
        this.snackBar.open('Request updated', 'SUCCESS', {duration: 3000});
    },
      error => {
        this.snackBar.open('Error updating request', 'ERROR', {duration: 3000});
    });
    this.dialogRef.close();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  // File Upload
  onClick() {
    const fileUpload = document.getElementById('fileUpload') as HTMLInputElement;
    fileUpload.onchange = () => {
      for (let index = 0; index < fileUpload.files.length; index++) {
        const file = fileUpload.files[index];
        this.files.push({
          data: file, state: 'in',
          inProgress: false, progress: 0, canRetry: false, canCancel: true
        });
      }
      this.uploadFiles();
    };
    fileUpload.click();
  }

  cancelFile(file: FileUploadModel) {
    file.sub.unsubscribe();
    this.removeFileFromArray(file);
  }

  retryFile(file: FileUploadModel) {
    file.canRetry = false;
  }

  private uploadFiles() {
    const fileUpload = document.getElementById('fileUpload') as HTMLInputElement;
    fileUpload.value = '';

    if (this.files.length >= 1) {

      const files = this.files.map(element => element.data);

      this.requestService.uploadRequestFile(this.data.id, files).subscribe(x => {
        this.files = [];
        this.snackBar.open('Patient file successfully uploaded', 'Success', {
          duration: 2000,
        });
      }, error => {
        this.snackBar.open('Patient file was not uploaded', 'Error', {
          duration: 2000,
        });
        this.files = [];
      });
    }
  }

  private removeFileFromArray(file: FileUploadModel) {
    const index = this.files.indexOf(file);
    if (index > -1) {
      this.files.splice(index, 1);
    }
  }

  // Download Template
  downloadTemplate() {
    this.requestService.getLabRequestFile(this.request.id).subscribe(blob => {
      FileSaver.saveAs(blob, this.request.patientName + ' ' + this.request.lineItem.name + '.pdf');
    });
  }

  printOrSend(printSend: PrintSend) {
    printSend = JSON.parse(JSON.stringify(printSend));
    printSend.clientId = this.request.clientId;
    printSend.patientId = this.request.patientId;
    printSend.recordId = this.request.id;
    printSend.recordType = this.request.requestType;

    this.requestService.sendLabRequestResults(this.request.patientId, printSend).subscribe(blob => {
      this.snackBar.open('Print or Send Complete', 'Success', {
        duration: 2000,
      });

      if (printSend.sendToClient === true) {
        this.request.requestCommunicationStatus = 'SentToClient';
      }
    },
    error => {
      this.snackBar.open('Could not send service results', 'Error', {
        duration: 2000,
      });
    });
  }

  setModelDate() {
    this.request.patientMedicalRecord.createdTimestamp = this.selectedDate;
    this.communication.createdTimestamp = this.selectedDate;
  }

  getAssigneesString(request: CommunicationRequestViewModel): string {
    return request.assignees.map(u => u.name).join(', ');
  }
}
