import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {Request} from '../../../models/request.interface';
import { RequestService } from '../../../services/request.service';
import {FormGroup, FormControl, Validators} from '@angular/forms';
import { FileUploadModel } from 'src/app/patient-management/patient-record/patient-picture-dialog/patient-picture.dialog';
import FileSaver from 'file-saver';
import { AccountService } from '../../../../configuration-management/services/account.service';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog/confirmation.dialog';
import { PrintSend } from 'src/app/shared/send-print-button/send-print.model';
import {PatientMedicalRecordBindingModel} from "../../../../patient-management/models/patient.interface";

@Component({
  selector: 'app-service-results',
  templateUrl: './service-results.dialog.html',
  styleUrls: ['./service-results.dialog.scss']
})
export class ServiceResultsDialogComponent implements OnInit {
  request: Request;
  model: any;
  medicalRecord: PatientMedicalRecordBindingModel;
  communication: PatientMedicalRecordBindingModel;
  reloadMedicalRecord = false;
  formIsValid: boolean;
  fetchingData = {
    count: 0,
    state: false
  };
  selectedDate: Date = new Date();
  public files: Array<FileUploadModel> = [];
  resultsForm = new FormGroup({
    requestNotes: new FormControl(''),
    emailNotes: new FormControl(''),
  });

  constructor(
    public dialogRef: MatDialogRef<ServiceResultsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Request,
    public snackBar: MatSnackBar,
    public requestService: RequestService,
    public accountService: AccountService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.getRequest();
  }

  getRequest() {
    this.requestService.getRequest(this.data.id).subscribe(res => {
      this.model = res;
      this.request = res;
      if (this.request.patientMedicalRecord.textBody) {
        this.medicalRecord = this.request.patientMedicalRecord;
        this.selectedDate = this.request.patientMedicalRecord.createdTimestamp;
      } else {
        this.medicalRecord = this.request.lineItem.email;
        this.selectedDate = this.request.lineItem.dateOfWork;
      }
      this.communication = this.medicalRecord;
      this.resultsForm.get('requestNotes').setValue(res.requestNotes);
    });
    this.checkFormIsValid();
  }

  // Handles conditional validation depending on the value of Care of Remains Status selector
  // READ THIS IF YOU EVER NEED TO ADD MORE THAN ONE VALIDATOR TO A FIELD
  // https://medium.com/ngx/3-ways-to-implement-conditional-validation-of-reactive-forms-c59ed6fc3325
  updateFormValidation() {
    switch (this.request.careOfRemainsStatus) {
      case 'OtherLocation':
      case 'DeliveredElsewhere':
        this.resultsForm.get('requestNotes').setValidators(Validators.required);
        this.resultsForm.controls.requestNotes.updateValueAndValidity();
        break;
      default:
        this.resultsForm.get('requestNotes').clearValidators();
        this.resultsForm.controls.requestNotes.updateValueAndValidity();
        break;
    }
    this.checkFormIsValid();
  }

  updateRequestNotes() {
    const requestNotes = this.resultsForm.get('requestNotes').value;
    this.requestService.updateRequestNotes(this.data.id, requestNotes).subscribe(res => {
      this.request.requestNotes = requestNotes;
      this.modelChanged();
      this.snackBar.open('Request notes have been updated', 'Success', {
        duration: 2000,
      });
      this.getRequest();
    },
      error => {
        this.snackBar.open('Request notes could not be updated', 'Error', {
          duration: 2000,
        });
      });
  }

  updateEmailNotes(emailNotes: string) {
    this.requestService.updateEmailNotes(this.data.id, emailNotes).subscribe(res => {
      this.request.emailNotes = emailNotes;
      this.modelChanged();
      this.snackBar.open('Email notes have been updated', 'Success', {
        duration: 2000,
      });
      this.getRequest();
    },
      error => {
        this.snackBar.open('Email notes could not be updated', 'Error', {
          duration: 2000,
        });
      });
  }

  setMedicalRecord(medicalRecord: PatientMedicalRecordBindingModel) {
    this.medicalRecord = medicalRecord;
    this.reloadMedicalRecord = true;

    setTimeout(() => {
      this.reloadMedicalRecord = false;
    });
  }

  updateFormInputs() {
    this.resultsForm = new FormGroup({
      requestNotes: new FormControl(this.request.requestNotes),
      emailNotes: new FormControl(this.request.emailNotes)
    });
  }

  checkFormIsValid() {
    this.formIsValid = this.resultsForm.valid;
  }

  cancel() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: {
        header: 'Cancel Lab Request',
        body: `You are about to cancel a lab request for ${this.model.lineItem.name}.`,
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.requestService.cancellRequest(this.request.id).subscribe(res => {

          this.snackBar.open('Request has been cancelled', 'Success', {
            duration: 2000,
          });
          this.dialogRef.close('reload');
        },
          error => {
            this.snackBar.open('Request could not be cancelled', 'Error', {
              duration: 2000,
            });
          });

      } else {
        // cancel
        this.snackBar.open('Operation cancelled.', '', {
          duration: 2000,
        });
      }
    });
  }

  checkValid(index): boolean {
    const control = this.resultsForm.get(['labResults', index]);

    return (control.valid) || (!control.valid && control.value.noAnswer === true);
  }

  modelChanged() {
    this.checkFormIsValid();
  }

  setMedicalNote(model: PatientMedicalRecordBindingModel) {
    this.communication = model;
  }

  updateFetchingData(fetching: boolean) {
    this.fetchingData.count = this.fetchingData.count + (fetching ? 1 : -1);
    this.fetchingData.state = this.fetchingData.count > 0;
  }

  submit() {
    this.modelChanged();
    this.request.requestNotes = this.resultsForm.get('requestNotes').value;
    this.request.emailNotes = this.resultsForm.get('emailNotes').value;
    this.request.patientMedicalRecord.createdTimestamp = this.selectedDate;

    if (this.data.isCompleted && this.request.patientMedicalRecord.htmlBody != null) {
      this.requestService.updateServiceResults(this.request.patientMedicalRecord, this.request,
          this.data.lineItem.itemId, this.data.lineItem.id)
        .subscribe(res => {
          this.snackBar.open('Service Results Successfully updated', 'Success', {
            duration: 2000,
          });
          this.dialogRef.close('Success');
        },
          error => {
            this.snackBar.open('Service Results were not updated', 'Error', {
              duration: 2000,
            });
          });
    } else {
      this.communication.createdTimestamp = this.selectedDate;
      this.medicalRecord.createdTimestamp = this.selectedDate;
      this.request.patientMedicalRecord = this.communication;
      this.request.isCompleted = true;
      this.requestService.setServiceResults(this.request.patientMedicalRecord, this.request,
        this.data.lineItem.itemId, this.data.lineItem.id)
        .subscribe(res => {
          this.snackBar.open('Service Results have been added', 'Success', {
            duration: 2000,
          });
          this.dialogRef.close('Success');
        }, error => {
          this.snackBar.open('Service Results were not added', 'Error', {
            duration: 2000,
          });
        });
    }
  }

  saveProgress() {
    if (this.formIsValid) {
      this.modelChanged();
      this.request.requestNotes = this.resultsForm.get('requestNotes').value;
      this.request.emailNotes = this.resultsForm.get('emailNotes').value;
      this.request.isCompleted = false;
      this.request.patientMedicalRecord = this.communication;

      if (this.request.patientMedicalRecord != null && this.request.patientMedicalRecord.htmlBody != null) {
        this.request.patientMedicalRecord.createdTimestamp = this.selectedDate;
        this.requestService.setServiceResults(this.request.patientMedicalRecord, this.request,
          this.data.lineItem.itemId, this.data.lineItem.id)
          .subscribe(res => {
            this.snackBar.open('Service Results have been added', 'Success', {
              duration: 2000,
            });
            this.dialogRef.close('Success');
          }, error => {
            this.snackBar.open('Service Results were not added', 'Error', {
              duration: 2000,
            });
          });
      } else {
        this.requestService.updateServiceResults(this.request.patientMedicalRecord, this.request,
          this.data.lineItem.itemId, this.data.lineItem.id)
          .subscribe(res => {
              this.snackBar.open('Service Results Successfully updated', 'Success', {
                duration: 2000,
              });
              this.dialogRef.close('Success');
            },
            error => {
              this.snackBar.open('Service Results were not updated', 'Error', {
                duration: 2000,
              });
            });
      }
    }
    else {
      this.snackBar.open('Please specify a location in the Notes field', 'ERROR', {duration: 3000});
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  // File Upload
  onClick() {
    const fileUpload = document.getElementById('fileUpload') as HTMLInputElement;
    fileUpload.onchange = () => {
      for (let index = 0; index < fileUpload.files.length; index++) {
        const file = fileUpload.files[index];
        this.files.push({
          data: file, state: 'in',
          inProgress: false, progress: 0, canRetry: false, canCancel: true
        });
      }
      this.uploadFiles();
    };
    fileUpload.click();
  }

  cancelFile(file: FileUploadModel) {
    file.sub.unsubscribe();
    this.removeFileFromArray(file);
  }

  retryFile(file: FileUploadModel) {
    file.canRetry = false;
  }

  private uploadFiles() {
    const fileUpload = document.getElementById('fileUpload') as HTMLInputElement;
    fileUpload.value = '';

    if (this.files.length >= 1) {

      var files = this.files.map(element => element.data);

      this.requestService.uploadRequestFile(this.data.id, files).subscribe(x => {
        this.files = [];
        this.snackBar.open('Patient file successfully uploaded', 'Success', {
          duration: 2000,
        });
      }, error => {
        this.snackBar.open('Patient file was not uploaded', 'Error', {
          duration: 2000,
        });
        this.files = [];
      });
    }
  }

  private removeFileFromArray(file: FileUploadModel) {
    const index = this.files.indexOf(file);
    if (index > -1) {
      this.files.splice(index, 1);
    }
  }

  // Download Template
  downloadTemplate() {
    this.requestService.getLabRequestFile(this.data.id).subscribe(blob => {
      FileSaver.saveAs(blob, this.data.patientName + ' ' + this.model.lineItem.name + '.pdf');
    });
  }

  printOrSend(printSend: PrintSend) {
    printSend = JSON.parse(JSON.stringify(printSend));
    printSend.clientId = this.request.clientId;
    printSend.patientId = this.request.patientId;
    printSend.recordId = this.request.id;
    printSend.recordType = this.request.requestType;

    this.requestService.sendLabRequestResults(this.request.patientId, printSend).subscribe(blob => {
      this.snackBar.open('Print or Send Complete', 'Success', {
        duration: 2000,
      });

      if (printSend.sendToClient === true) {
        this.data.requestCommunicationStatus = 'SentToClient';
      }
    },
    error => {
      this.snackBar.open('Could not send service results', 'Error', {
        duration: 2000,
      });
    });
  }

  setModelDate() {
    this.request.patientMedicalRecord.createdTimestamp = this.selectedDate;
    this.communication.createdTimestamp = this.selectedDate;
  }
}




