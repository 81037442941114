import { Injectable } from '@angular/core';
import { BaseService } from '../../helpers/base.service';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import {
  Request,
  LabResultRequest,
  RequestPageModel,
  RequestFilters,
  OrphanHeskaTests,
  CommunicationRequest
} from '../models/request.interface';
import { LabRequestResult } from 'src/app/whiteboard/models/order.interface';
import { Page } from 'src/app/configuration-management/models/user.interface';
import { PrintSend } from '../../shared/send-print-button/send-print.model';
import { EmailViewModel } from 'src/app/configuration-management/models/email.interface';
import {PatientMedicalRecordBindingModel} from "../../patient-management/models/patient.interface";

@Injectable({
  providedIn: 'root',
})
export class RequestService extends BaseService {

  public setLabRequestResults(labResults: LabRequestResult[], request: Request, labId: string, lineItemId: string): Observable<LabRequestResult[]> {
    if (!this.isConnected) {
      return;
    }

    const route = `${this.apiUrl}/Request/SaveLabResults/${labId}/lineItem/${lineItemId}`;
    const body = JSON.stringify({ labResults, request });
    return this.http.post<LabRequestResult[]>(route, body, this.headers()).pipe(map(response => {
      console.log("Lab request updated here", response)
      return response;
    }));
  }

  public updateLabRequestResults(labResults: LabRequestResult[], request: Request, labId: string, lineItemId: string): Observable<LabRequestResult[]> {
    if (!this.isConnected) {
      return;
    }

    const route = `${this.apiUrl}/Request/UpdateLabResults/${labId}/lineItem/${lineItemId}`;
    const body = JSON.stringify({ labResults, request });
    return this.http.post<LabRequestResult[]>(route, body, this.headers()).pipe(map(response => {
      console.log('Lab request updated', response);
      return response;
    }));
  }

  public setServiceResults(patientMedicalRecord: PatientMedicalRecordBindingModel, request: Request, serviceId: string, lineItemId: string): Observable<LabRequestResult[]> {
    if (!this.isConnected) {
      return;
    }

    const route = `${this.apiUrl}/Request/SaveServiceResults/${serviceId}/lineItem/${lineItemId}`;
    const body = JSON.stringify({ patientMedicalRecord, request });
    return this.http.post<LabRequestResult[]>(route, body, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public updateServiceResults(patientMedicalRecord: PatientMedicalRecordBindingModel, request: Request, labId: string, lineItemId: string): Observable<LabRequestResult[]> {
    if (!this.isConnected) {
      return;
    }

    const route = `${this.apiUrl}/Request/UpdateServiceResults/${labId}/lineItem/${lineItemId}`;
    const body = JSON.stringify({ patientMedicalRecord, request });
    return this.http.post<LabRequestResult[]>(route, body, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public getLabRequestResults(serviceId: string, lineItemId: string, requestId: string): Observable<LabResultRequest> {
    if (!this.isConnected) {
      return;
    }
    const route = `${this.apiUrl}/Request/LabResults/${serviceId}/lineItem/${lineItemId}/request/${requestId}`;

    return this.http.get<LabResultRequest>(route, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public changeLabRequestStatus(requestId: string, requestStatus: string): Observable<LabResultRequest> {
    if (!this.isConnected) {
      return;
    }
    const route = `${this.apiUrl}/Request/${requestId}/changeLabRequestStatus`;

    const body = JSON.stringify(requestStatus);

    return this.http.post<LabResultRequest>(route, body, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public sendLabRequestResults(patientId: string, model: PrintSend): any {
    if (!this.isConnected) {
      return;
    }
    const route = `${this.apiUrl}/Patient/${patientId}/SendMedicalLabResult`;

    const body = JSON.stringify(model);

    return this.http.post(route, model, this.fileHeaders()).pipe(map(res => {
      return new Blob([res], { type: 'application/pdf' });
    }));
  }

  public getRequest<type = Request>(requestId: string): Observable<type> {
    if (!this.isConnected) {
      return;
    }
    const route = `${this.apiUrl}/Request/GetRequest/${requestId}`;

    return this.http.get<type>(route, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public updateRequestNotes(requestId: string, requestNotes: string): Observable<Request> {
    if (!this.isConnected) {
      return;
    }
    const route = `${this.apiUrl}/Request/${requestId}/UpdateRequestNotes`;

    const body = JSON.stringify(requestNotes);
    return this.http.post<Request>(route, body, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public updateEmailNotes(requestId: string, emailNotes: string): Observable<Request> {
    if (!this.isConnected) {
      return;
    }
    const route = `${this.apiUrl}/Request/${requestId}/UpdateEmailNotes`;

    const body = JSON.stringify(emailNotes);
    return this.http.post<Request>(route, body, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public getRequests(page: Page): Observable<RequestPageModel> {
    if (!this.isConnected) {
      return;
    }
    const body = JSON.stringify(page);
    const route = `${this.apiUrl}/Request/GetRequests`;

    return this.http.post<RequestPageModel>(route, body, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public getResults(page: Page): Observable<RequestPageModel> {
    if (!this.isConnected) {
      return;
    }
    const body = JSON.stringify(page);
    const route = `${this.apiUrl}/Request/GetResults`;

    return this.http.post<RequestPageModel>(route, body, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public getRequestFilters(): Observable<RequestFilters> {
    if (!this.isConnected) {
      return;
    }

    const route = `${this.apiUrl}/Request/GetRequestFilters`;

    return this.http.get<RequestFilters>(route, this.headers()).pipe(map(response => {
      return response;
    }));
  }


  // Request Crud
  public makeRequest(model: any): Observable<any> {

    const body = JSON.stringify(model);
    const route = `${this.apiUrl}/Request/MakeRequest`;

    return this.http.post<RequestPageModel>(route, body, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public createCommunicationRequest(model: CommunicationRequest): Observable<any> {

    const body = JSON.stringify(model);
    const route = `${this.apiUrl}/Request/MakeCommunicationRequest`;

    return this.http.post<CommunicationRequest>(route, body, this.headers()).pipe(map(res => {
      return res;
    }));
  }

  public updateCommunicationRequest(model: CommunicationRequest): Observable<any> {

    const body = JSON.stringify(model);
    const route = `${this.apiUrl}/Request/UpdateCommunicationRequest`;

    return this.http.post<CommunicationRequest>(route, body, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public respondToCommunicationRequest(model: CommunicationRequest): Observable<any> {

    const body = JSON.stringify(model);
    const route = `${this.apiUrl}/Request/RespondToCommunicationRequest`;

    return this.http.post<CommunicationRequest>(route, body, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public genericEmailForCommunicationRequest(requestIds: string[]): Observable<any> {

    const body = JSON.stringify(requestIds);
    const route = `${this.apiUrl}/Request/GenericEmailForCommunicationRequest`;

    return this.http.post<any>(route, body, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public updateRefillRequest(model: any, requestId: string): Observable<any> {

    const body = JSON.stringify(model);
    const route = `${this.apiUrl}/Request/UpdateRefillRequest/${requestId}`;

    return this.http.post<RequestPageModel>(route, body, this.headers()).pipe(map(response => {
      return response;
    }));

  }

  public updateRequestLocation(branchId: string, requestId: string): Observable<any> {


    const route = `${this.apiUrl}/Request/UpdateRequestLocation/${requestId}/${branchId}`;

    return this.http.post<any>(route, null, this.headers()).pipe(map(response => {
      return response;
    }));

  }

  public cancellRequest(requestId: string): Observable<string> {


    const route = `${this.apiUrl}/Request/CancellRequest/${requestId}`;

    return this.http.post<string>(route, null, this.headers()).pipe(map(response => {
      return response;
    }));

  }

  public approveRequest(requestId: string): Observable<string> {


    const route = `${this.apiUrl}/Request/ApproveRequest/${requestId}`;

    return this.http.post<string>(route, null, this.headers()).pipe(map(response => {
      return response;
    }));

  }

  public pickedUpRequest(requestId: string): Observable<string> {


    const route = `${this.apiUrl}/Request/PickedUpRequest/${requestId}`;

    return this.http.post<string>(route, null, this.headers()).pipe(map(response => {
      return response;
    }));

  }

  public completeRequest(requestId: string): Observable<string> {


    const route = `${this.apiUrl}/Request/CompleteRequest/${requestId}`;

    return this.http.post<string>(route, null, this.headers()).pipe(map(response => {
      return response;
    }));

  }

  public completeRefillRequest(model: Request): Observable<RequestPageModel> {
    const body = JSON.stringify(model);
    const route = `${this.apiUrl}/Request/CompleteRefillRequest/${model.id}`;

    return this.http.post<RequestPageModel>(route, body, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public setPickedUp(model: Request): Observable<RequestPageModel> {
    const body = JSON.stringify(model);
    const route = `${this.apiUrl}/Request/PickedUpRequest/${model.id}`;

    return this.http.post<RequestPageModel>(route, body, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public setDroppedOff(model: Request): Observable<RequestPageModel> {
    const body = JSON.stringify(model);
    const route = `${this.apiUrl}/Request/DroppedOffRequest/${model.id}`;

    return this.http.post<RequestPageModel>(route, body, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  // Lab PDFS
  public uploadRequestFile(requestId: string, files: File[]): Observable<any> {
    const route = `${this.apiUrl}/Request/${requestId}/UploadFile`;

    let formData: FormData = new FormData();
    files.forEach(fileToUpload => {
      formData.append("file", fileToUpload, fileToUpload.name);
    });

    return this.http.post<any>(route, formData).pipe(map(response => {
      return response;
    }));
  }

  public uploadLabRequestFileTemporarilySavedOnQuickEntry(files: File[]): Observable<any> {
    const route = `${this.apiUrl}/Request/UploadLabRequestFileTemporarilySavedOnQuickEntry`;

    let formData: FormData = new FormData();
    files.forEach(fileToUpload => {
      formData.append("file", fileToUpload, fileToUpload.name);
    });

    return this.http.post<any>(route, formData).pipe(map(response => {
      return response;
    }));
  }

  getLabRequestFile(requestId: string): any {
    const route = `${this.apiUrl}/Request/${requestId}/GetFile`;
    return this.http.get(route, this.fileHeaders()).pipe(map(res => {
      return new Blob([res], { type: 'application/pdf' });
    }));
  }

  //Lab Request Queue
  getXMLWorklist(branchId: string): any {
    const route = `${this.apiUrl}/Request/GetXMLWorklist/${branchId}`;
    return this.http.get(route, this.fileHeaders()).pipe(map(response => {
      return new Blob([response], { type: 'text/xml' });
    }));
  }

  getOrphanTasks(pageNumber: number, pageSize: number): Observable<OrphanHeskaTests> {
  const route = `${this.apiUrl}/Request/GetOrphanHeskaTests/${pageNumber}/${pageSize}`;
    return this.http.get<OrphanHeskaTests>(route, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  assignOrphanHeskaTest(requestId: string, testId: string): any {
    const route = `${this.apiUrl}/Request/AssignOrphanHeskaTest/${requestId}/${testId}`;

    return this.http.post(route, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  hideOrphanHeskaTest(testId: string): any {
    const route = `${this.apiUrl}/Request/HideOrphanHeskaTest/${testId}`;

    return this.http.post(route, this.headers()).pipe(map(response => {
      return response;
    }));
  }
}
