import { Client } from 'src/app/client-management/models/client.interface';
import { Patient } from 'src/app/patient-management/models/patient.interface';

export class EmailListViewModel {
    emails: EmailViewModel[] = [];
}
export class EmailViewModel {
    htmlBody: string;
    textBody = '';
    id: string;
    emailType: string;
    dateCreated: Date;
    dateUpdated: Date;
    description: string;
    isReminder: boolean = false;
    isMedicalNoteTemplate: boolean = false;
    inputResults: InputResults[] = [];
    sendEmail: boolean;
    createdTimestamp: Date;
    // Used for quick entry drafts
    selectedClient: Client;
    selectedPatient: Patient;
    emailTemplateId: string;
}


export class InputResults {
    value: string;
    identifier: string;
}

export class ClientEmailDraft {
    patientId: string;
    medicalRecord: EmailViewModel;
}
